import { on } from 'delegated-events';

import smallCartRefresh from '../cart/small-cart-refresh';
import { showLoader, hideLoader } from '../utils/utils';
import showPopup from '../modals';
import { maskProductsAmount } from './cart-management.js';

export default function () {
    on('click', '[data-cart-command]', function (e) {
        const arg = this.value;
        const command = this.getAttribute("data-cart-command");

        cartCommand(command, arg);
    });

    on('click', ".payment-channels-modal .goshop-modal [data-channel-id]", function () {
        window.currentModalInstance.close();

        cartCommand("set-channel", this.getAttribute("data-channel-id"));
    });

    on('click', "#validate-voucher", function (e) {
        cartCommand("voucher", document.getElementById("voucher-value").value);
    });
}

function cartCommand(command, arg) {
    showLoader();

    fetch('/cartaction/command', {
        method: 'POST',
        credentials: 'same-origin',
        body: JSON.stringify({
            request: {
                command: command,
                argument: arg
            }
        }),
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        }
    })
        .then(response => response.json())
        .then(data => {
            document.getElementById('cartaction-content').innerHTML = data.Html;
            smallCartRefresh();
            window.lazyLoadInstance.update();
            hideLoader();

            if (data.PositionChanges) {
                for (const item of data.PositionChanges) {
                    document.dispatchEvent(new CustomEvent('goshop:cart_action', { detail: item }));
                }
            }

            if (data.PositionsCount == 0) {
                document.location.href = "/emptycart";
            }

            if (data.ErrorMessage) {
                showPopup({ body: data.ErrorMessage, overloadClass: 'error-modal' });
            }

            maskProductsAmount('.cart-product-amount input[type="text"]');
        }).catch(error => {
            console.log(error);
            hideLoader();
        });
}

export { cartCommand };